import React, { useState } from 'react';
import styles from './styles/StaticPage.module.scss'
import { Col, Row } from 'react-bootstrap';
import { SubTitle, Button, Input } from '../../Components/CustomComponents';

const ContactUs = () => {

    const [adminEmail, setAdminEmail] = useState("");

    const handleAdminEmail = (e) => {
        // console.log(e.target.value);
        setAdminEmail(e.target.value);
    };

    const handleSaveText = () => {
        // console.log(value
        console.log(adminEmail)
    }

    return (
        <div className={styles.StaticPage}>
            <Row>
                <Col sm={10}>
                    <SubTitle
                        text='Contact Us'
                        classes='mb-4'
                    />
                </Col>
                <Col sm={2}>
                    <Button
                        classes={`${styles.cardBtn} `}
                        text="Save"
                        onClick={handleSaveText}
                    ></Button>
                </Col>
            </Row>
            <p className='text-white'>Set your email, App user will contact you on this email.</p>
            <Row>
                <Col md={12}>
                    <p className="m-0 text-white" >
                        Email
                    </p>
                    <Input

                        classes="mt-2 mb-3"
                        type="email"
                        placeholder="Enter Admin Email"
                        value={adminEmail}

                        onChange={handleAdminEmail}
                    />
                </Col>
            </Row>


        </div>
    )
}

export default ContactUs
