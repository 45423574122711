import React, { useMemo } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom';

// global scss for globally styles and fonts
import './_fonts.scss';
import './_global.scss';

import { WEB_ROUTES } from "./constants.js";
import { baseRoute } from "./Helper";
import PublicRoute from "./Components/Routes/PublicRoute.js";

import Account from "./Pages/Account";
import ProtectedLayout from "./Components/Layouts/ProtectedLayout.js";
import Dashboard from "./Pages/Dashboard";
// import Tournaments from "./Pages/Tournaments";
// import Messages from "./Pages/Messages";
import Setting from "./Pages/Setting";
// import Profile from "./Pages/Profile";
// import PaymentMethod from "./Pages/PaymentMethod";
import ProtectedRoute from "./Components/Routes/ProtectedRoute.js";
import UserManagement from "./Pages/UserManagement";
import SendNotificationScreen from "./Pages/SendNotification/SendNotificationScreen.js";
import { uniqueId } from "lodash";
// import TournamentManagementScreen from "./Pages/TournamentManagement/TournamentManagementScreen.js";
// import TournamentManagement from './Pages/TournamentManagement';
function App() {
  // todo: menu
  const ROUTES = useMemo(() => {
    return [
      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.ACCOUNT)} element={
        <PublicRoute >
          <Account />
        </PublicRoute>
      } />,
      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.DASHBOARD)} element={
        <ProtectedRoute>
          <ProtectedLayout isSearchBar={false} selectedRoute={0}>
            <Dashboard />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,
      // <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.TOURNAMENT_MANAGEMENT)} element={
      //   <ProtectedRoute>
      //     <ProtectedLayout isSearchBar={false} selectedRoute={1}>
      //       <TournamentManagement />
      //     </ProtectedLayout>
      //   </ProtectedRoute>
      // } />,
      // <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.PLAYER_MANAGEMENT)} element={
      //   <ProtectedRoute>
      //     <ProtectedLayout isSearchBar={true} selectedRoute={2}>
      //       <PlayerManagement />
      //     </ProtectedLayout>
      //   </ProtectedRoute>
      // } />,
      // <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.COACH_MANAGEMENT)} element={
      //   <ProtectedRoute>
      //     <ProtectedLayout isSearchBar={true} selectedRoute={3}>
      //       <CoachManagement />
      //     </ProtectedLayout>
      //   </ProtectedRoute>
      // } />,
      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.USER_MANAGEMENT)} element={
        <ProtectedRoute>
          <ProtectedLayout isSearchBar={true} selectedRoute={1}>
            <UserManagement />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,
      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.SEND_NOTIFICATION)} element={
        <ProtectedRoute>
          <ProtectedLayout isSearchBar={false} selectedRoute={2}>
            <SendNotificationScreen />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,
      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.SETTING)} element={
        <ProtectedRoute>
          <ProtectedLayout isSearchBar={false} selectedRoute={3}>
            <Setting />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,
      // <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.PROFILE)} element={
      //   <ProtectedRoute>
      //     <ProtectedLayout isSearchBar={false} selectedRoute={2}>
      //       <Profile />
      //     </ProtectedLayout>
      //   </ProtectedRoute>
      // } />,

    ]

  }, [])

  return (
    <Router>
      <Routes>
        {ROUTES.map((item) => item)}
        <Route path={baseRoute('*')} element={<Navigate to={baseRoute(WEB_ROUTES.ACCOUNT)} />} />
      </Routes >


    </Router >
  );
}

export default App;
